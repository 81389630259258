/** @format */
import { Box, Button, Stack, Tooltip } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { ComponentError, TinyError } from "errors";
import React, { useState } from "react";

import { useWorkspace } from "@Workspace/Context";
import { useActions } from "actions";
import { useLocales } from "locales";
import { Tip, useTour } from "tour";
import { useUI } from "ui";
import { useUser } from "user";

import Checklist from "./components/Checklist";
import Features from "./components/Features";

export default function Left() {
  const workspace = useWorkspace();
  const { t } = useLocales();
  const tour = useTour();
  const { meta } = useUser();
  const { menu } = useUI();

  const action = useActions().run.action;

  const [checklist, setChecklist] = useState(false);

  if (!workspace.ready.get()) return <></>;

  const toggleChecklist = () => {
    setChecklist(!checklist);
  };

  return (
    <ComponentError>
      <Stack
        alignItems="space-between"
        sx={{
          minHeight: "100vh",
          width: "40px",
          position: "fixed",
          alignItems: "center",
          zIndex: 1200,
        }}
      >
        <Stack
          spacing={1}
          sx={{
            height: "calc(100vh - 50px)",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TinyError>
            <Tooltip title={t("checklist")} placement="left">
              <Box>
                <Tip title={t("tourChecklist")} name="checklist" tour={tour.name} placement="left">
                  <Button variant="small-tab" onClick={toggleChecklist}>
                    <DynamicIcon icon={"fa-list-check"} />
                  </Button>
                </Tip>
              </Box>
            </Tooltip>
          </TinyError>
          <TinyError>
            <Tooltip title={t("project")} placement="left">
              <Box>
                <Tip title={t("tourProject")} name="project" tour={tour.name} placement="left">
                  <Button variant="small-tab" onClick={() => action.project.open(0)}>
                    <DynamicIcon icon={"fa-gear"} />
                  </Button>
                </Tip>
              </Box>
            </Tooltip>
          </TinyError>
          <Features workspace={workspace} t={t} action={action} tour={tour} />
        </Stack>
        <Stack>{!meta.get("rightDesk") && <Box id="assist-button-portal"></Box>}</Stack>
      </Stack>
      <Checklist open={checklist} toggleChecklist={toggleChecklist} show="all" />
    </ComponentError>
  );
}
